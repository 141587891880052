import React, { FC, ReactNode } from 'react'

import { styled } from '@linaria/react'
import { animated, useTransition } from '@react-spring/web'

import { ANIMATION_DURATION_MS } from './constants'
import { formaDJRCyrillicMicroFontFamily } from '../shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'

export const ErrorText: FC<{ errorText?: ReactNode }> = ({ errorText }) => {
  const transitions = useTransition(Boolean(errorText), {
    from: { opacity: 0, height: '0%' },
    enter: { opacity: 1, height: '100%' },
    leave: { opacity: 0 },
    config: { duration: ANIMATION_DURATION_MS },
  })

  return transitions(
    (springs, show) =>
      show && <StyledErrorText style={springs}>{errorText}</StyledErrorText>
  )
}

const StyledErrorText = styled(animated.p)`
  margin-top: var(--spacing-2x, 8px);
  padding-left: var(--spacing-6px, 6px);
  color: var(--error-default, #ff3f15);
  font-family: ${formaDJRCyrillicMicroFontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.28px;

  html[dir='ltr'] & {
    text-align: left;
  }
  html[dir='rtl'] & {
    text-align: right;
  }
`
