import React, {
  CSSProperties,
  ComponentProps,
  ReactNode,
  forwardRef,
} from 'react'

import { cx, css } from '@linaria/core'
import { styled } from '@linaria/react'

import { textOverflowStyles } from 'common/styles/textOverflow'
import { WithClassName } from 'common/types'

import { ANIMATION_DURATION_MS } from './constants'
import { ErrorText } from './ErrorText'
import { formaDJRCyrillicTextFontFamily } from '../shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'
import { Typography } from '../Typography/Typography'

type InputProps = Omit<ComponentProps<typeof StyledInput>, 'size'> & {
  label?: ReactNode
  invalid?: boolean
  errorText?: ReactNode
  elementLeft?: ReactNode
  elementRight?: ReactNode
  inputInnerCss?: string
  inputCss?: string
  inputStyle?: CSSProperties
  size?: Size
} & WithClassName

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    inputCss,
    inputStyle,
    inputInnerCss,
    label,
    value,
    placeholder,
    onChange,
    errorText,
    invalid,
    elementLeft,
    elementRight,
    size = 'L',
    ...restProps
  } = props

  return (
    <InputWrapper className={cx(className)}>
      {label && <Label fontSize={14}>{label}</Label>}
      <InputInner className={inputInnerCss}>
        {elementLeft}
        <StyledInput
          style={inputStyle}
          className={cx(sizeStylesMap[size], inputCss)}
          ref={ref}
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={500}
          aria-invalid={invalid || Boolean(errorText)}
          {...restProps}
        />
        {elementRight}
      </InputInner>
      <ErrorText errorText={errorText} />
    </InputWrapper>
  )
})

const InputWrapper = styled.div`
  width: 100%;
`
const InputInner = styled.div`
  position: relative;
`
const Label = styled(Typography)`
  color: var(--foreground-muted, #99908e);
  padding-left: var(--spacing-6px, 6px);
  margin-bottom: var(--spacing-8px, 8px);
  ${textOverflowStyles};
`
const StyledInput = styled.input`
  width: 100%;
  border: none;
  margin: 0;
  border-radius: 16px;
  border: var(--spacing-2px, 2px) solid
    var(--border-default, rgba(127, 116, 114, 0.48));
  background: var(--background-surface-1, #fff);
  color: var(--foreground-default, #2e2a29);
  font-family: ${formaDJRCyrillicTextFontFamily};
  font-style: normal;
  transition: border-color ${ANIMATION_DURATION_MS}ms;
  caret-color: var(--mamba-default, #ff8640);

  /* Hide the spin buttons on number input fields in Firefox */
  -moz-appearance: textfield;

  &::placeholder {
    /* 
     * Фиксит вертикальное выравнивание плейсхолдера на iOS 13
     * https://github.com/necolas/normalize.css/issues/736#issuecomment-627474292
     */
    line-height: normal !important;
    vertical-align: middle;
    /* конец фикса */
    color: var(--foreground-muted, #99908e);
    font-family: ${formaDJRCyrillicTextFontFamily};
    font-style: normal;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:not(:disabled):not([aria-invalid='true']):hover {
    border-color: var(--border-hover, #7f7472);
  }

  &:focus {
    outline: none;
    border-color: var(--border-press, #706664);
  }

  &[aria-invalid='true'] {
    border-color: var(--error-default, #ff3f15);
  }
`

type Size = 'L' | 'M'

const sizeStylesMap: Record<Size, string> = {
  L: css`
    font-size: 24px;
    line-height: 24px;
    padding: 10px 12px;
    letter-spacing: 0.32px;
    font-weight: 500;

    &::placeholder {
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.32px;
      font-weight: 500;
    }
  `,
  M: css`
    font-size: 16px;
    line-height: 16px;
    padding: 10px 12px;
    letter-spacing: 0.48px;
    font-weight: 400;

    &::placeholder {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.48px;
      font-weight: 400;
    }
  `,
}
