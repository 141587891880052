import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { goBackAction, goBackToAction } from 'actions/route/routeAction'
import { findPrevStepUrl } from 'components/page/StepRegistration/StepRegistration.functions'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const prevStepAction = (currentPath: string) => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    locationsHistory,
    systemReducer: { locale },
  } = getState()

  // История переходов есть - просто идем назад
  if (locationsHistory.browserHistory.length > 1) {
    dispatch(goBackAction())
    return
  }

  // Истории переходов нет - вручную переходим на предыдущий шаг
  dispatch(goBackToAction(mergeAllUrls(locale, findPrevStepUrl(currentPath))))
}
