import React, { FC, KeyboardEventHandler, useEffect, useRef } from 'react'

import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'

import { stepRegisterWithEmailAction } from 'actions/form/stepRegisterWithEmailAction'
import { updateRegistrationFieldAction } from 'actions/form/stepRegistrationAction'
import { Input } from 'components/designSystem/Input/Input'
import { MainLayout } from 'components/designSystem/layout/MainLayout/MainLayout'
import { landingLayoutContextId } from 'components/layout/MambaLayout/Context'
import { CreateBaseUrl } from 'components/layout/MambaLayout/CreateContext'
import { StepContent } from 'components/page/StepRegistration/StepContent'
import { LargeInput } from 'components/page/StepRegistration/StepRegistration.styled'
import { SubmitGroup } from 'components/page/StepRegistration/SubmitGroup'
import { enterEmailPath } from 'components/paths'
import {
  Field,
  Form,
  resolveFormFieldValue,
} from 'components/presentational/form'
import {
  AppInputChangeHandler,
  InvalidMessage,
} from 'components/presentational/input'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { isValidEmail } from 'functions/isValidEmail'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useRedesign } from 'hooks/useRedesign'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import {
  StepRegistrationFieldList,
  stepRegistrationReducer,
} from 'reducers/registration/stepRegistrationReducer'

const messages = defineMessages({
  email: {
    id: 'app.email',
    defaultMessage: 'Электронная почта',
  },
})

export const EnterEmail: FC = () => {
  useReducersInsert({ stepRegistration: stepRegistrationReducer })

  const intl = useIntl()
  const dispatch = useDispatch()
  const emailRef = useRef<HTMLInputElement>(null)
  const { email, formErrors, authorizing, locale } = useShallowEqualSelector(
    ({
      stepRegistration: {
        form: { email },
        formErrors,
      },
      authorizationReducer: { authorizing },
      systemReducer: { locale },
    }) => ({ email, formErrors, authorizing, locale })
  )
  const redesign = useRedesign()
  const { url, path } = useRouteMatch()

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus()
    }
  }, [])

  const handleChange: AppInputChangeHandler = (event) => {
    const { name, value } = resolveFormFieldValue(event.target)
    dispatch(
      updateRegistrationFieldAction(
        name as StepRegistrationFieldList,
        value as string
      )
    )
  }

  const handleSubmit = () => {
    dispatch(stepRegisterWithEmailAction())
  }

  if (redesign) {
    const emailValid = isValidEmail(email)

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (event.key === 'Enter' && emailValid) {
        handleSubmit()
      }
    }

    return (
      <CreateBaseUrl
        uid="enter-email"
        contextId={landingLayoutContextId}
        baseUrl={mergeAllUrls(locale)}
        basePath={mergeAllUrls(locale)}
      >
        <MainLayout
          title={
            <FormattedMessage
              id="app.enter_email"
              defaultMessage="Введи электронную почту"
            />
          }
          primaryButtonText={
            <FormattedMessage
              id="app.link.email"
              defaultMessage="Привязать почту"
            />
          }
          onPrimaryButtonClick={handleSubmit}
          primaryButtonDataName="enter-email-action"
          primaryButtonDisabled={!emailValid}
          primaryButtonLoading={authorizing}
          wrapperStyle={{ backgroundColor: 'var(--pearl-default, #FFDED0)' }}
        >
          <Input
            name="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            required
            disabled={authorizing}
            errorText={formErrors.email}
          />
        </MainLayout>
      </CreateBaseUrl>
    )
  }

  return (
    <StepContent path={enterEmailPath}>
      <Form id={'stepRegistration'} onSubmit={handleSubmit}>
        <StyledModalTitle>
          <FormattedMessage
            id="registration.step.email_enter"
            defaultMessage="Введите электронную почту"
          />
        </StyledModalTitle>
        <Field>
          <LargeInput
            name={StepRegistrationFieldList.email}
            type={'text'}
            ref={emailRef}
            value={email}
            maxLength={500}
            onChange={handleChange}
            placeholder={intl.formatMessage(messages.email)}
            required
            message={
              <InvalidMessage>
                <FormattedMessage id="form.email_error" />
              </InvalidMessage>
            }
          />
          {formErrors.email && (
            <InvalidMessage>{formErrors.email}</InvalidMessage>
          )}
        </Field>
        <SubmitGroup loading={authorizing} />
      </Form>
    </StepContent>
  )
}
