import styled from 'styled-components'

import { media } from 'components/presentational'

export const LinkWrapper = styled.div`
  ${(props) => props.theme.marginRight}: 20px;
  ${media.tablet`
    margin-bottom: 10px;
  `};
`
