import React, { CSSProperties, ComponentProps, FC, ReactNode } from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'

import { goBackAction } from 'actions/route/routeAction'
import { VoidHandler } from 'common/types'
import { ButtonsBlock } from 'components/designSystem/Button/ButtonsBlock'
import { PrimaryButton } from 'components/designSystem/Button/PrimaryButton'
import { SecondaryButton } from 'components/designSystem/Button/SecondaryButton'
import { Loader } from 'components/designSystem/Loader/Loader'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { applyIfAnimationsEnabledCss } from 'components/designSystem/styles/applyIfAnimationsEnabledCss'
import { buttonResetCss } from 'components/designSystem/styles/buttonResetCss'
import { fadeInCss } from 'components/designSystem/styles/fadeInCss'
import { ArrowBackSvg } from 'components/designSystem/svgr/ArrowBackSvg'
import { CloseSvg } from 'components/designSystem/svgr/CloseSvg'
import { Typography } from 'components/designSystem/Typography/Typography'
import { logoutPath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push } from 'functions/router'
import { useAppDispatch } from 'hooks/useAppDispatch'

export const MainLayout: FC<{
  icon?: ReactNode
  title?: ReactNode
  titleFontSize?: ComponentProps<typeof Typography>['fontSize']
  description?: ReactNode
  children?: ReactNode
  primaryButtonText?: ReactNode
  primaryButtonTo?: string
  primaryButtonHref?: string
  primaryButtonDataName?: string
  onPrimaryButtonClick?: VoidHandler
  secondaryButtonText?: ReactNode
  secondaryButtonTo?: string
  secondaryButtonHref?: string
  onSecondaryButtonClick?: VoidHandler
  primaryButtonLoading?: boolean
  primaryButtonDisabled?: boolean
  secondaryButtonLoading?: boolean
  secondaryButtonDisabled?: boolean
  secondaryButtonDataName?: string
  footerElement?: ReactNode
  backButtonVisible?: boolean
  logOutButtonVisible?: boolean
  wrapperStyle?: CSSProperties
  innerCss?: string
  noBorder?: boolean
  loading?: boolean
}> = ({
  icon,
  title,
  titleFontSize,
  description,
  children,
  primaryButtonText,
  primaryButtonTo,
  primaryButtonHref,
  primaryButtonDataName,
  onPrimaryButtonClick,
  secondaryButtonText,
  secondaryButtonTo,
  secondaryButtonHref,
  onSecondaryButtonClick,
  primaryButtonLoading,
  primaryButtonDisabled,
  secondaryButtonLoading,
  secondaryButtonDisabled,
  secondaryButtonDataName,
  footerElement,
  backButtonVisible = true,
  logOutButtonVisible,
  wrapperStyle,
  innerCss,
  noBorder,
  loading,
}) => {
  const dispatch = useAppDispatch()

  const handleBackClick = () => {
    dispatch(goBackAction())
  }
  const handleLogOutClick = () => {
    dispatch(push(logoutPath))
  }

  return (
    <MainLayoutWrapper style={wrapperStyle}>
      <MainLayoutInner className={cx(noBorder && noBorderCss, innerCss)}>
        <MainLayoutContent>
          {(backButtonVisible || logOutButtonVisible) && (
            <MainLayoutHeader>
              <div>
                {backButtonVisible && (
                  <ArrowBack
                    onClick={handleBackClick}
                    data-name="go-back-action"
                  >
                    <ArrowBackSvg />
                  </ArrowBack>
                )}
              </div>
              <div>
                {logOutButtonVisible && (
                  <LogOutButton
                    onClick={handleLogOutClick}
                    data-name="logout-action"
                  >
                    <CloseSvg />
                  </LogOutButton>
                )}
              </div>
            </MainLayoutHeader>
          )}

          {loading ? (
            <Loader />
          ) : (
            <>
              <Content>
                {
                  <>
                    <TitleBlock>
                      <TitleInner>
                        {icon}
                        {title && (
                          <Title>
                            <Typography
                              fontSize={titleFontSize ?? 24}
                              fontWeight={700}
                            >
                              {title}
                            </Typography>
                          </Title>
                        )}
                      </TitleInner>
                      <Description>
                        <Typography fontSize={16}>{description}</Typography>
                      </Description>
                    </TitleBlock>
                    {children && <Body>{children}</Body>}
                  </>
                }
              </Content>

              <Footer>
                {footerElement || (
                  <>
                    {primaryButtonText && (
                      <PrimaryButton
                        to={primaryButtonTo}
                        href={primaryButtonHref}
                        onClick={onPrimaryButtonClick}
                        loading={primaryButtonLoading}
                        // Если второстепенная кнопка loading, то основная должна быть disabled, чтобы ее не нажали
                        disabled={Boolean(
                          primaryButtonDisabled || secondaryButtonLoading
                        )}
                        data-name={primaryButtonDataName}
                      >
                        {primaryButtonText}
                      </PrimaryButton>
                    )}

                    {secondaryButtonText && (
                      <SecondaryButton
                        color="mamba"
                        to={secondaryButtonTo}
                        href={secondaryButtonHref}
                        onClick={onSecondaryButtonClick}
                        loading={secondaryButtonLoading}
                        // Если основная кнопка loading, то второстепенная должна быть disabled, чтобы ее не нажали
                        disabled={Boolean(
                          secondaryButtonDisabled || primaryButtonLoading
                        )}
                        data-name={secondaryButtonDataName}
                      >
                        {secondaryButtonText}
                      </SecondaryButton>
                    )}
                  </>
                )}
              </Footer>
            </>
          )}
        </MainLayoutContent>
      </MainLayoutInner>
    </MainLayoutWrapper>
  )
}

const Title = styled.div``
const Description = styled.div``
const Body = styled.div`
  width: 100%;
`
const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & > * + * {
    margin-top: var(--spacing-24px, 24px);
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-grow: 1;
  }
`
const TitleBlock = styled.div`
  padding-left: var(--spacing-6px, 6px);
  padding-right: var(--spacing-6px, 6px);

  & > * + * {
    margin-top: var(--spacing-16px, 16px);
  }
`
const TitleInner = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * + * {
    margin-top: var(--spacing-32px, 32px);
  }
`
const ArrowBack = styled.button`
  ${buttonResetCss};
  padding: 12px 16px;
`
const LogOutButton = styled.button`
  ${buttonResetCss};
  position: relative;
  right: 0;
  padding: 12px 16px;
`
const MainLayoutHeader = styled.header`
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 100%;
  }
  @media (min-width: ${breakpoints.mobile}px) {
    top: 20px;
    left: 20px;
    right: 20px;
  }
`
const MainLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const Footer = styled(ButtonsBlock)`
  margin-top: var(--spacing-32px, 32px);
`
const MainLayoutInner = styled.div`
  width: 100%;
  height: 100%;
  padding: var(--spacing-32px, 32px) var(--spacing-16px, 16px)
    var(--spacing-16px, 16px);

  @media (min-width: ${breakpoints.mobile}px) {
    overflow: hidden;
    position: relative;
    html[dir='ltr'] & {
      left: 50%;
      transform: translate(-50%, -50%);
    }
    html[dir='rtl'] & {
      right: 50%;
      transform: translate(50%, -50%);
    }
    top: 50%;
    width: 560px;
    max-height: 796px;
    padding: 124px 80px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 2px solid var(--border-default, rgba(127, 116, 114, 0.48));
  }
  @media screen and (min-width: ${breakpoints.mobile}px) and (max-height: 900px) {
    max-height: 720px;
  }
`
const MainLayoutContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  ${applyIfAnimationsEnabledCss(fadeInCss)}
`
const noBorderCss = css`
  border: none;
`
